// extracted by mini-css-extract-plugin
export const sectionSpacing = "ninas-module--section-spacing--1wRtf";
export const rowSpacing = "ninas-module--row-spacing--2bdc1";
export const rowSpacingSml = "ninas-module--row-spacing-sml--2oVHl";
export const intro = "ninas-module--intro--3uoPv";
export const inspiracion = "ninas-module--inspiracion--1D65y";
export const inspRow = "ninas-module--inspRow--nx3E_";
export const inspiracionesPosts = "ninas-module--inspiracionesPosts--10iAE";
export const perfilesRow = "ninas-module--perfilesRow--2hW8U";
export const perfiles = "ninas-module--perfiles--2MfQc";
export const profiles = "ninas-module--profiles--1P4TU";
export const orientacion = "ninas-module--orientacion--QWwkw";
export const emulador = "ninas-module--emulador--1fggR";
export const recursos = "ninas-module--recursos--1Q4TO";
export const extResBox = "ninas-module--extResBox--3F-hL";
export const kits = "ninas-module--kits--2BwD9";