// import dependencies
import React from "react";
import {graphql, Link} from "gatsby";
import Image from "gatsby-plugin-sanity-image";

// import components
import BigCard from "../components/big-card";
import Button from "../components/button";
import Card from "../components/cards/card-empty";
import Header from "../components/header";
import Kits from "../components/kits";
import Layout from "../components/layout";
import PostCard from "../components/cards/card-post";
import Section from "../components/section";
import SectionHeader from "../components/section-header";
import SeparatorBottomTeal from "../components/separators/separator-bottom-teal";
import SeparatorBottomYellow from "../components/separators/separator-bottom-yellow";
import SeparatorTopGrey from "../components/separators/separator-top-grey";
import SeparatorTopTeal from "../components/separators/separator-top-teal";
import SeparatorTopYellow from "../components/separators/separator-top-yellow";

// import styles and assets
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../utils/font-awesome";
import photo1 from "../images/ninas-page/insp-blob.png";
import photo1Mob from "../images/ninas-page/insp-blob-mobile.png";
import photo2 from "../images/ninas-page/ninas-2a-min.jpg";
import photo3 from "../images/ninas-page/ninas-1d-min.jpg";
import photoEmu from "../images/emulador-page/emulador.png";
import * as styles from "./styles/ninas.module.scss";

const NinasPage = ({data}) => (
<Layout>
    <Section color="white">
        <Header bkgImage={photo1} mobileBkgImage={photo1Mob}>
            <h1>¡Inspírate en STEM!</h1>
        </Header>
    </Section>
    <SeparatorTopYellow/>
    <Section color="yellow">
        <BigCard color="yellow">
            <div className="col-sm-12 order-sm-first col-md-6 order-md-last align-self-center">
                <img src={photo2} alt="¿que es stem?"/>
            </div>
            <div className="d-flex flex-column col-sm-12 order-sm-last col-md-6 order-md-first align-self-center">
                <h1>STEM: Habilidades en ciencia y tecnología</h1>
                <h4>¿Has escuchado alguna vez la palabra STEM? ¿Qué te imaginas cuando la escuchas? Conoce todo lo que tienes que saber de las STEM.</h4>
                <Button url="/inspiracion/post-qué-son-las-stem-y-por-qué-son-importantes" color="turquoise" buttonText="Descubre aquí"></Button>
            </div>
        </BigCard>
    </Section>
    <Section color="turquoise">
        <SeparatorBottomYellow />
        <div className={`container ${styles.inspiracion}`}>
            <SectionHeader color="white">
                <h1>Áreas STEM</h1>
                <h4>Conoce todas las ramas que componen las STEM y ¡descubre cuáles son las que más te motivan!</h4>
            </SectionHeader>
            <div className="row row-spacing">
                <div className="col-sm-12 align-self-center">
                    <div className={`row ${styles.inspRow}`}>
                        <div className={styles.inspiracionesPosts}>
                            {data.inspirations.edges.map((edge) => (
                                <PostCard key={edge.node.id}>
                                    <div className="photo" key={edge.node.mainImage.asset.id}>
                                        <Image 
                                            {...edge.node.mainImage}
                                            width={470}
                                            height={380}
                                            style={{
                                                width: "100%",
                                                height: "100%",
                                                objectFit: "cover",
                                            }}
                                            alt="Imágen de áreas STEM" />
                                    </div>
                                    <div className="box">
                                        <h4>{edge.node.title}</h4>
                                        <div className="separator"></div>
                                        <p>{edge.node.excerpt}</p>
                                        <Link to={`/inspiracion/${edge.node.slug.current}`}><FontAwesomeIcon icon={["fas", "arrow-right"]} /></Link>
                                    </div>
                                </PostCard>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12 d-flex justify-content-center">
                    <Button url="/inspiracion" color="turquoise" buttonText="Conoce más"></Button>
                </div>
            </div>
        </div>
    </Section>
    <SeparatorBottomTeal />
    <Section color="white">
        <div className={`container ${styles.perfiles}`}>
            <SectionHeader color="turquoise">
                <h1>Grandes Ingeniosas</h1>
                <h4>Mujeres destacadas en STEM te llevarán a descubrir, desde sus experiencias, la ciencia y tecnología.</h4>
            </SectionHeader>
            <div className={`row ${styles.perfilesRow}`}>
                <div className="col-sm-12 align-self-center">
                    <div className={styles.profiles}>
                        {data.profiles.nodes.map((node) => (
                            <PostCard key={node.id}>
                                <div className="profilePhoto">
                                    <Image 
                                        {...node.mainImage}
                                        width={470}
                                        height={380}
                                        style={{
                                            width: "100%",
                                            height: "100%",
                                            objectFit: "cover",
                                        }}
                                        alt="Imágen de Perfiles y biografías" />
                                </div>
                                <div className="profileText">
                                    <h4>{node.title}</h4>
                                    <div className="sep"></div>
                                    <p>{node.excerpt}</p>
                                    <Link to={`/grandes-ingeniosas/${node.slug.current}`}><FontAwesomeIcon icon={["fas", "arrow-right"]} /></Link>
                                </div>
                            </PostCard>
                        ))}
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12 d-flex justify-content-center">
                    <Button url="/grandes-ingeniosas/" color="turquoise" buttonText="Conoce más" />
                </div>
            </div>
        </div>
    </Section>
    <SeparatorTopTeal />
    <Section color="turquoise">
        <BigCard color="turquoise">
            <div className="col-sm-12 order-sm-first col-md-6 order-md-last align-self-center">
                <img src={photo3} alt="orientación vocacional"/>
            </div>
            <div className="d-flex flex-column col-sm-12 order-sm-last col-md-6 order-md-first align-self-center">
                <h1>Orientación vocacional</h1>
                <h4>¡Las STEM son un mundo de posibilidades para tu futuro! Descubre sobre carreras profesionales y técnicas y cómo es estudiarlas.</h4>
                <Button url="/orientacion-vocacional" color="green" buttonText=" Conoce más" />
            </div>
        </BigCard>
    </Section>
    <SeparatorBottomTeal />
    <Section color="white">
        <BigCard color="white">
            <div className="col-sm-12 col-md-6 align-self-center">
                <img src={photoEmu} alt="emulador stem"/>
            </div>
            <div className="d-flex flex-column col-sm-12 col-md-6 align-self-center">
                <h1>Emulador STEM</h1>
                <h4>Descubre las STEM de manera virtual y remota, a través de nuestro laboratorio virtual. ¡Ejercicios de ciencia y tecnología estarán disponibles para ti!</h4>
                <Button url="/ninas-emulador" color="turquoise" buttonText="Entra al emulador" />
            </div>
        </BigCard>
    </Section>
    <SeparatorTopYellow />
    <Section color="yellow">
        <div className={`container section-spacing ${styles.recursos}`}>
            <SectionHeader color="white">
                <h1>Recursos externos</h1>
                <h4>Accede a un mundo de herramientas STEM que pueden ser útiles para tu desarrollo. Te invitamos a conocer diferentes recursos que te ayudarán a descubrir más de ciencia y tecnología.</h4>
            </SectionHeader>
            <div className={`row ${styles.extResBox}`}>
                {data.extRes.edges.map((edge) => (              
                    <div key={edge.node.id} className="col-sm-12 col-md-6 col-lg-4">
                        <Card desc={edge.node.title} postUrl={`/recursos-externo/${edge.node.slug.current}`}/>
                    </div>
                ))}
            </div>
        </div>
    </Section>
    <SeparatorBottomYellow />
    <Section color="white">
        <div className={`container section-spacing ${styles.kits}`}>
            <SectionHeader color="turquoise">
                <h1>Cajas interactivas</h1>
                <h4>Kit de Herramientas físicas que te permitirán realizar actividades y experimentos en ciencia y tecnología. </h4>
            </SectionHeader>
            <Kits/>
        </div>
    <SeparatorTopGrey />
    </Section>
</Layout>
);
export default NinasPage;

export const query = graphql`
query PaginaNinasQueries {
    inspirations: allSanityPost(
    limit: 3,
    filter: {category: {title: {eq: "Inspiración"}}, tags: {elemMatch: {title: {eq: "Niñas"}}}},
    sort: {order: DESC, fields: publishedAt}) {
        edges {
            node {
                id
                title
                tags {
                    title
                }
                slug {
                    current
                }
                excerpt
                publishedAt
                mainImage {
                    ...ImageWithPreview
                }
            }
        }
    }
    profiles: allSanityPost(
    limit: 3, 
    filter: {category: {title: {in: ["Biografías", "Perfiles"]}}}, 
    sort: {order: DESC, fields: publishedAt}) {
        nodes {
            id
            title
            slug {
                current
            }
            excerpt
            mainImage {
                ...ImageWithPreview
            }
        }
    }
    extRes: allSanityPost(
    limit: 6, 
    filter: {category: {title: {eq: "Recursos externos"}}, tags: {elemMatch: {title: {eq: "Niñas"}}}}, 
    sort: {order: DESC, fields: publishedAt}) {
        edges {
            node {
                id
                title
                slug {
                   current
                }
            }
        }
    }
}
`;